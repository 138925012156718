import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  getEnumOptions,
  GlobalPackageCount,
  ImportGlobalPackageDto,
  ModuleKeywords,
  TargetTypeEnum,
} from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';

@Component({
  selector: 'app-import-global-package-item-form',
  templateUrl: './import-global-package-item-form.component.html',
  styleUrls: ['./import-global-package-item-form.component.scss'],
})
export class ImportGlobalPackageItemFormComponent extends BaseForm<ImportGlobalPackageDto> implements OnInit {
  // @ViewChild('fileBrowser', { static: false }) fileBrowser: FileUpload;
  // showCancelFile = false;

  // cancelFileSelectionAction: IAction = {
  //   id: 2,
  //   label: 'Cancel',
  //   buttonType: 'button',
  //   command: this.clearFile.bind(this),
  //   icon: 'pi pi-times',
  // };
  targetTypeOptions = getEnumOptions(TargetTypeEnum).map((x) => {
    return { type: x.value };
  });
  constructor(
    public viewModeService: ViewModeService,
    private fileService: FilesDataService
  ) {
    super(viewModeService, ModuleKeywords.ImportGlobalPackage);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? {
          ...this.getDataKeyValueFormat(),
          linkedDocuments: this.formGroup.controls.documentUrl.value ? [this.formGroup.controls.documentUrl.value] : [],
        }
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      documentUrl: new FormControl(null, Validators.required),
      overrideUuid: new FormControl(false),
      description: new FormControl(null),
      counts: new FormControl(null),
      errorMessage: new FormControl(null),
    });
  }
  onTargetTypeChange(targetType) {}
  // onBasicUpload(event) {
  //   for (let file of event.files) {
  //     this.fileService.uploadFile(file, { description: file.name, versionName: file.name }).subscribe((res) => {
  //       this.formGroup.controls.documentUrl.patchValue(res.fileId);
  //       this.clearFile(this.fileBrowser);
  //     });
  //   }
  // }
  // onSelectFile() {
  //   this.showCancelFile = true;
  // }
  // clearFile(fileBrowser) {
  //   fileBrowser?.clear();
  //   this.showCancelFile = false;
  // }
  onFileChange(event: { files?: any[] }) {
    this.loadBlobContent(event?.files?.[0]);
  }
  loadBlobContent(file) {
    const reader = new FileReader();

    // Once the file is loaded, the result will be the content of the blob
    reader.onload = () => {
      const fileContent = reader.result; // Store the file content here
      if (fileContent) {
        const a = JSON.parse(fileContent as string);
        if (a?.name) {
          this.formGroup?.controls?.name?.patchValue(a?.name);
        }
        if (a?.description) {
          this.formGroup?.controls?.description?.patchValue(a?.description);
        }
        if (a?.recordCounts) {
          const aCounts: GlobalPackageCount[] = [];
          Object.entries(a?.recordCounts).forEach(([key, value]: any) => {
            aCounts.push({ count: value, type: key });
          });
          this.formGroup?.controls?.counts?.patchValue(aCounts);
        }
      }
    };

    // Read the blob as text
    reader.readAsText(file);
  }
}
