<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12">
      <app-attachment-input
        [label]="'Package File'"
        [control]="formGroup?.controls?.documentUrl"
        [viewMode]="fieldViewMode"
        [dragAndDropMode]="true"
        [uploadLocation]="'CODE'"
        [useGlobalPasteEventListener]="false"
        [staticUploadWidth]="false"
        (onFilesChange)="onFileChange($event)"
      ></app-attachment-input>
    </div>
    @if (formGroup?.controls?.counts?.value) {
      <div class="col-12">
        <div class="field w-full">
          <div class="flex flex-row md:flex-nowrap flex-wrap gap-2 align-items-start overflow-hidden">
            <span class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full font-semibold text-500">
              {{ 'Types To Import' }}
            </span>

            <div class="flex gap-2 align-items-start w-full min-w-0">
              <div [class]="'flex-row flex flex-wrap w-full gap-2 min-w-0'">
                @for (item of formGroup?.controls?.counts?.value; track item) {
                  <div class="flex flex-row">
                    <app-badge-item [status]="item.type" [styleClass]="'border-noround-right'"></app-badge-item>
                    <div
                      pTooltip="Count of Records To Import"
                      tooltipPosition="top"
                      class="px-1 surface-300 border-round-right text-sm font-semibold"
                    >
                      {{ item?.count }}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    <!-- <div class="col-12">
      <app-drop-down-input
        [label]="'Types to Import'"
        [placeholder]="'Types to Import'"
        [name]="'dropdown'"
        [optionLabel]="'type'"
        [optionValue]="undefined"
        [dataKey]="'type'"
        [items]="targetTypeOptions"
        [control]="formGroup?.controls?.counts"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
        [multi]="true"
      ></app-drop-down-input>
    </div> -->
    <!-- {{ formGroup?.controls?.counts?.value | json }} -->
    <!-- <div class="col-12 md:col-6">
      <ng-container>
        <app-basic-input
          label="Package File Id"
          [placeholder]="'Enter Package File Id'"
          [control]="formGroup?.controls?.documentUrl"
          [viewMode]="'create'"
        ></app-basic-input>
      </ng-container>
    </div>

    <div class="col-12 md:col-6 flex gap-2 align-items-end mb-3">
      <p-fileUpload
        mode="basic"
        name="documentUrl"
        [styleClass]="'px-3'"
        [chooseLabel]="'Browse'"
        (uploadHandler)="onBasicUpload($event)"
        [customUpload]="true"
        (onSelect)="onSelectFile()"
        #fileBrowser
      ></p-fileUpload>
      @if (showCancelFile) {
        <app-button [action]="cancelFileSelectionAction" [commandData]="fileBrowser"></app-button>
      }
    </div> -->
    <div class="col-12 md:col-6">
      <app-switch-input
        [label]="'Override UUID'"
        [name]="'overrideUuid'"
        [viewMode]="fieldViewMode"
        [control]="formGroup?.controls?.overrideUuid"
      ></app-switch-input>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'115px'"
        [name]="'description'"
        label="Package Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="'create'"
      ></app-text-editor>
    </div>
  </div>
  <!-- <ng-content></ng-content> -->
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
